/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines */
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import React, { Suspense, useEffect, useMemo, useRef, useState } from 'react'
import { Resizable } from 'react-resizable'

import Box from '@mui/material/Box'
import { ProgressIndicator, useI18n, useRouter } from '@popety_io/popety-io-lib'
import { useDispatch, useSelector } from 'react-redux'

import { PopetyMapsScreenButtonRoot } from '../LandDetails/LandDetails.style'
import { CustomHandle } from '../LandDetails/LandDetails'
import EstimateMap from './components/EstimateMap'
import { getEstimateMenuData } from './components/EstimateMenu'
import EstimateSearch from './components/EstimateSearch'
import {
  estimateGeoCenterSelector,
  estimateSelector,
  getEstimateSummary,
} from './redux'

import useGetEstimateData from './Estimate.data'
import { EstimateRoot, LeftContainer, RightContainer } from './Estimate.style'

const Estimate = () => {
  const { t } = useI18n()
  const { query, updateQuery } = useRouter()
  const dispatch = useDispatch()
  const geoCenterEstimate = useSelector(estimateGeoCenterSelector)
  const estimateGeoCenterStr = useSelector(estimateGeoCenterSelector)
  const estimate = useSelector(estimateSelector)
  const openSearch = query?.openNewReport === 'true'

  const leftContainerRef = useRef<HTMLDivElement>()
  const rightContainerRef = useRef<HTMLDivElement>()

  const isHomePage = query?.tab === 'home'

  const pages: any = getEstimateMenuData(estimate, isHomePage, t)

  const geoCenterChanged = query?.geoCenter !== estimateGeoCenterStr

  const screenSizeMap = JSON.parse(
    localStorage.getItem('screenSizeMap') || '{}',
  )
  const screenSizeMapEstimate = screenSizeMap?.estimateMap
  const [size, setSize] = useState(
    screenSizeMapEstimate?.fullscreen || 'default',
  )
  const [width, setWidth] = useState(screenSizeMapEstimate?.width || 42)
  const [parentWidth, setParentWidth] = useState(0)
  const content = useMemo(() => {
    if (!query || !pages?.length) return

    if (query?.subTab) {
      return pages
        ?.find((p: any, i: number) =>
          !query?.tab
            ? i === 0
            : p?.key === query?.tab ||
              p?.subMenus?.find((s: any) => s?.key === query?.tab),
        )
        ?.subMenus?.find((s: any) => s?.key === query?.subTab)?.page
    }

    const page: any = pages
      .filter((menu: any) => menu?.show || menu?.key === 'history')
      .find((p: any, i: number) =>
        !query?.tab ? i === 0 : p?.key === query?.tab,
      )

    if (page?.subMenus?.length && !query?.subTab) {
      updateQuery({
        subTab: page?.subMenus?.[0]?.key,
      })
    }

    return page?.page
  }, [query?.tab, query?.subTab, pages])

  useGetEstimateData()

  useEffect(() => {
    if (leftContainerRef.current) {
      const rect = leftContainerRef.current.getBoundingClientRect()

      setParentWidth(rect.width)
      if (screenSizeMapEstimate) {
        if (
          screenSizeMapEstimate?.fullscreen &&
          screenSizeMapEstimate?.fullscreen === 'large'
        ) {
          setSize(screenSizeMapEstimate?.fullscreen)
          setWidth(100)
        } else {
          setSize('default')
          setWidth(screenSizeMapEstimate?.width || 42)
        }
      } else {
        setSize('default')
        setWidth(screenSizeMapEstimate?.width || 42)
      }
    }
  }, [])
  useEffect(() => {
    if (query?.geoCenter && geoCenterChanged) {
      updateQuery({ month: 24, dealType: 'purchase' })
      dispatch(
        getEstimateSummary({
          geoCenter: query?.geoCenter,
          addressId: query?.addressId,
          estimateId: query?.estimateId,
        }) as any,
      )
    }
  }, [query?.geoCenter, geoCenterChanged])

  const fullscreen = size === 'large'

  const handleCancelModal = () => {
    const keys = Object.keys(query)

    keys.forEach((key) => {
      if (key !== 'tab') {
        updateQuery({ [key]: undefined })
      }
    })
  }

  const handleClose = () => {
    updateQuery({ openNewReport: undefined, createEstimate: undefined })
  }

  const handleToggleFullScreen = (size: string) => {
    if (size === 'fullScreen') {
      setWidth(100)
      setSize('large')
      localStorage.setItem(
        'screenSizeMap',
        JSON.stringify({
          ...screenSizeMap,
          estimateMap: {
            ...screenSizeMap?.estimateMap,
            fullscreen: 'large',
            width: 100,
          },
        }),
      )
    } else {
      setWidth(screenSizeMapEstimate?.oldWidth || 70)
      setSize('default')
      localStorage.setItem(
        'screenSizeMap',
        JSON.stringify({
          ...screenSizeMap,
          estimateMap: {
            ...screenSizeMap?.estimateMap,
            fullscreen: 'default',
            width: screenSizeMapEstimate?.oldWidth || 70,
          },
        }),
      )
    }
  }
  const onResize = (
    _: React.SyntheticEvent,
    { size }: { size: { width: number; height: number } },
  ) => {
    if (size.width / parentWidth > 0.7) return
    setWidth((size.width / parentWidth) * 100)
    const openTimeout = setTimeout(() => {
      localStorage.setItem(
        'screenSizeMap',
        JSON.stringify({
          ...screenSizeMap,
          estimateMap: {
            fullscreen: 'default',
            width: (size.width / parentWidth) * 100,
            oldWidth: (size.width / parentWidth) * 100,
          },
        }),
      )
    }, 50)

    return () => {
      clearTimeout(openTimeout)
    }
  }

  return (
    <EstimateRoot bgcolor="background.paper">
      {openSearch && (
        <EstimateSearch
          showCloseIcon={!!geoCenterEstimate}
          open={openSearch}
          onClose={handleClose}
          onCancelModal={handleCancelModal}
        />
      )}

      <Box
        height="100%"
        display="flex"
        justifyContent={{ xs: 'flex-end', lg: 'flex-start' }}
        flexDirection={{ xs: 'column-reverse', lg: 'row' }}
        ref={leftContainerRef}
      >
        <LeftContainer
          bgcolor="background.paper"
          width={{
            xs: '100%',
            lg: `${100 - width}%`,
          }}
          display={fullscreen ? 'none' : undefined}
          data-cy="land-detail-left-container"
          id="estimate-scroll-left"
        >
          <Suspense fallback={<ProgressIndicator circular />}>
            {content}
          </Suspense>
        </LeftContainer>
        <Resizable
          resizeHandles={['sw', 'nw', 'w']}
          axis="x"
          width={(parentWidth * width) / 100}
          onResize={onResize}
          handle={
            <CustomHandle>
              <ArrowBackIosIcon />
            </CustomHandle>
          }
        >
          <RightContainer
            width={{
              xs: '100%',
              lg: `${width}%`,
            }}
            ref={rightContainerRef}
          >
            <Box position="relative" height="100%">
              {content && (
                <PopetyMapsScreenButtonRoot>
                  {fullscreen ? (
                    <FullscreenExitIcon
                      sx={{
                        fontSize: '2rem',
                      }}
                      onClick={() => handleToggleFullScreen('default')}
                    />
                  ) : (
                    <FullscreenIcon
                      sx={{
                        fontSize: '2rem',
                      }}
                      onClick={() => {
                        handleToggleFullScreen('fullScreen')
                      }}
                    />
                  )}
                </PopetyMapsScreenButtonRoot>
              )}
              <EstimateMap fullscreen={fullscreen} />
            </Box>
          </RightContainer>
        </Resizable>
      </Box>
    </EstimateRoot>
  )
}

export default Estimate
