/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines */
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import Box from '@mui/material/Box'
import {
  ProgressIndicator,
  fetchOnce,
  useI18n,
  useRouter,
} from '@popety_io/popety-io-lib'
import { Suspense, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Resizable } from 'react-resizable'

import { useLocation } from 'react-router-dom'
import { styled } from '@mui/material'
import { regionsByNameSelector } from '../../components/LocationsInput'
import { getCurrentUserDetails } from '../Account/redux'
import { userSelector } from '../Login'
import useGetLandDetailsData from './LandDetails.data'
import LandDetailsMap from './LandDetailsMap'
import { getLandDetailsMenuData } from './components/LandDetailsMenu'
import LandSearch from './components/LandSearch'
import {
  getLandSummary,
  landIdStringSelector,
  landRegionSelector,
} from './redux'

import {
  LandDetailsRoot,
  LeftContainer,
  PopetyMapsScreenButtonRoot,
  RightContainer,
} from './LandDetails.style'

export const toggleSizeMap = (size: string) => {
  if (size === 'large') return 100
  if (size === 'small') return 30

  return 42
}
export const CustomHandle = styled(Box)({
  position: 'absolute',
  left: 0,
  top: 0,
  height: '100%',
  width: '10px',
  backgroundColor: 'transparent',
  cursor: 'ew-resize',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:hover': {
    backgroundColor: '#dfe5ec80',
    width: '20px', // Tăng kích thước handle khi hover
  },
  '&:hover svg': {
    display: 'block', // Hiện icon khi hover
  },
  '& svg': {
    display: 'none', // Ẩn icon khi không hover
    color: 'white', // Màu icon
    fontSize: '16px', // Kích thước icon
  },
})

const LandDetails = () => {
  const { t } = useI18n()
  const { query, updateQuery } = useRouter()
  const landIdStr = useSelector(landIdStringSelector)
  const [openSearch, setOpenSearch] = useState(!!query?.openNewAnalysis)

  const leftContainerRef = useRef<HTMLDivElement>()
  const rightContainerRef = useRef<HTMLDivElement>()

  const dispatch: any = useDispatch()

  const landRegion = useSelector(landRegionSelector)
  const regionsByName = useSelector(regionsByNameSelector)

  const firstTransactionDate = regionsByName[landRegion]?.first_transaction_date
  const firstFaoDate = regionsByName[landRegion]?.first_fao_date
  const user = useSelector(userSelector)
  const isHomePage = query?.tab === 'home' || query?.tab === 'grouping'
  const location = useLocation()

  const screenSizeMap = JSON.parse(
    localStorage.getItem('screenSizeMap') || '{}',
  )
  const screenSizeMapLandDetail = screenSizeMap?.landDetail
  const [size, setSize] = useState(
    screenSizeMapLandDetail?.fullscreen || 'default',
  )
  const [width, setWidth] = useState(screenSizeMapLandDetail?.width || 42)
  const [parentWidth, setParentWidth] = useState(0)

  useGetLandDetailsData()

  const canAnalyse = useMemo(() => {
    return (
      user.can_exceed_max_analysis ||
      Number(user.analysis_counter) < Number(user?.analysis_nb) ||
      (user?.listsAnalysis && user?.listsAnalysis?.includes(landIdStr))
    )
  }, [user])

  const pages = getLandDetailsMenuData(
    t,
    firstTransactionDate,
    firstFaoDate,
    user?.company_id === '1' ||
      user?.company_id === '167' ||
      user?.company_id === '419' /* POPETY company id */,
    canAnalyse,
    isHomePage,
  )

  const content = useMemo(() => {
    if (!query || !pages?.length) return

    if (query?.subTab) {
      return pages
        ?.find((p: any, i: number) =>
          !query?.tab
            ? i === 0
            : p?.key === query?.tab ||
              p?.subMenus?.find((s: any) => s?.key === query?.tab),
        )
        ?.subMenus?.find((s: any) => s?.key === query?.subTab)?.page
    }

    const page: any = pages
      .filter((menu: any) => menu?.show || menu?.key === 'history')
      .find((p: any, i: number) =>
        !query.tab
          ? i === 0
          : isHomePage
            ? p?.key === query?.tab && location.pathname === p?.path
            : p?.key === query?.tab,
      )

    if (page?.subMenus?.length && !query?.subTab) {
      updateQuery({
        subTab: page?.subMenus?.[0]?.key,
      })
    }

    return page?.page
  }, [query?.tab, query?.subTab, pages])

  const fullscreen = size === 'large'

  const handleClose = () => {
    updateQuery({ openNewAnalysis: undefined })
  }

  useEffect(() => {
    setOpenSearch(!!query?.openNewAnalysis)
  }, [query?.openNewAnalysis])

  useEffect(() => {
    if (query.landId) {
      fetchOnce(
        'landDetailAnalysis',
        {
          landId: query.landId,
          canAnalyse,
        },
        () => dispatch(getLandSummary({ landId: query.landId, canAnalyse })),
      )
    }
  }, [query.landId, canAnalyse])

  useEffect(() => {
    fetchOnce('getCurrentUserDetails', user?.id, () => {
      dispatch(getCurrentUserDetails())
    })
  }, [user?.id])

  useEffect(() => {
    if (leftContainerRef.current) {
      const rect = leftContainerRef.current.getBoundingClientRect()

      setParentWidth(rect.width)
      if (screenSizeMapLandDetail) {
        if (
          screenSizeMapLandDetail?.fullscreen &&
          screenSizeMapLandDetail?.fullscreen === 'large'
        ) {
          setSize(screenSizeMapLandDetail?.fullscreen)
          setWidth(100)
        } else {
          setSize('default')
          setWidth(screenSizeMapLandDetail?.width || 42)
        }
      } else {
        setSize('default')
        setWidth(screenSizeMapLandDetail?.width || 42)
      }
    }
  }, [])

  const handleToggleFullScreen = (size: string) => {
    if (size === 'fullScreen') {
      setWidth(100)
      setSize('large')
      localStorage.setItem(
        'screenSizeMap',
        JSON.stringify({
          ...screenSizeMap,
          landDetail: {
            ...screenSizeMap?.landDetail,
            fullscreen: 'large',
            width: 100,
          },
        }),
      )
    } else {
      setWidth(screenSizeMapLandDetail?.oldWidth || 70)
      setSize('default')
      localStorage.setItem(
        'screenSizeMap',
        JSON.stringify({
          ...screenSizeMap,
          landDetail: {
            ...screenSizeMap?.landDetail,
            fullscreen: 'default',
            width: screenSizeMapLandDetail?.oldWidth || 70,
          },
        }),
      )
    }
  }
  const onResize = (
    _: React.SyntheticEvent,
    { size }: { size: { width: number; height: number } },
  ) => {
    if (size.width / parentWidth > 0.7) return
    setWidth((size.width / parentWidth) * 100)
    const openTimeout = setTimeout(() => {
      localStorage.setItem(
        'screenSizeMap',
        JSON.stringify({
          ...screenSizeMap,
          landDetail: {
            fullscreen: 'default',
            width: (size.width / parentWidth) * 100,
            oldWidth: (size.width / parentWidth) * 100,
          },
        }),
      )
    }, 50)

    return () => {
      clearTimeout(openTimeout)
    }
  }

  return (
    <LandDetailsRoot bgcolor="background.paper">
      <LandSearch
        showCloseIcon={!!landIdStr}
        open={openSearch}
        onClose={handleClose}
      />
      <Box
        height="100%"
        display="flex"
        justifyContent={{ xs: 'flex-end', lg: 'flex-start' }}
        flexDirection={{ xs: 'column-reverse', lg: 'row' }}
        ref={leftContainerRef}
      >
        <LeftContainer
          bgcolor="background.paper"
          width={{
            xs: '100%',
            lg: `${100 - width}%`,
          }}
          display={fullscreen ? 'none' : undefined}
          data-cy="land-detail-left-container"
        >
          <Suspense fallback={<ProgressIndicator circular />}>
            {content}
          </Suspense>
        </LeftContainer>
        <Resizable
          handle={
            <CustomHandle>
              <ArrowBackIosIcon />
            </CustomHandle>
          }
          resizeHandles={['w']}
          axis="x"
          width={(parentWidth * width) / 100}
          onResize={onResize}
        >
          <RightContainer
            width={{
              xs: '100%',
              lg: `${width}%`,
            }}
            ref={rightContainerRef}
          >
            <Box position="relative" height="100%">
              {content && (
                <PopetyMapsScreenButtonRoot>
                  {fullscreen ? (
                    <FullscreenExitIcon
                      sx={{
                        fontSize: '2rem',
                      }}
                      onClick={() => handleToggleFullScreen('default')}
                    />
                  ) : (
                    <FullscreenIcon
                      sx={{
                        fontSize: '2rem',
                      }}
                      onClick={() => {
                        handleToggleFullScreen('fullScreen')
                      }}
                    />
                  )}
                </PopetyMapsScreenButtonRoot>
              )}
              <LandDetailsMap fullscreen={fullscreen} />
            </Box>
          </RightContainer>
        </Resizable>
      </Box>
    </LandDetailsRoot>
  )
}

export default LandDetails
